<template>
    <div>
    </div>
</template>
<script>
export default {
    methods: {
        setToken() {
            const email = this.$route.query.email
            const token = this.$route.query.token

            localStorage.setItem("user-email", email);
            localStorage.setItem("user-token", token);
            localStorage.setItem("redirect-to-user-account", "yes");

            // redirect to 
           location.reload()
        }
    },
    mounted() {
        this.setToken()
    }
}
</script>